
import Vue from 'vue'
import Component from 'vue-class-component'
import { Mutation } from 'vuex-class'
import ManageTable from '@/components/shared/ManageTable.vue'
import RecipientList from '@/components/shared/RecipientList.vue'
import { TransferRow } from '@/presentation/remittance/model/TransferRows'
import Recipient from '@/models/recipient/Recipient'
import MemberInfo from '@/models/MemberInfo'
import { WaitRemittanceGroup } from '@/gateway/remittance/WaitRemittanceGroup'
import { MemberInfoGateway } from '@/gateway/commons/MemberInfoGateway'

@Component({
  components: { RecipientList, ManageTable }
})
export default class SelectRecipientManually extends Vue {
  @Mutation setValidatedTransfers: any
  @Mutation setManualValidatedTransfers: any
  @Mutation disableLoading: any

  countryWithMethods: Array<string> = []
  recipients: Dictionary<Array<Recipient>> = {}
  transferWithMethod: Dictionary<Array<TransferRow>> = {}
  recipientIncludeFilter: Array<string> = ['corp_pid', 'country', 'full_name', 'first_name', 'middle_name', 'last_name', 'master_code', 'bank_account_number', 'remittance_method_type']
  recipientListUnit: number = 10

  handleSelectedRecipients (selectedRecipients: any): void {
    if (!selectedRecipients) {
      this.$message({ message: '선택된 수취인이 없습니다.', type: 'error' })
      return
    }
    this.recipients = selectedRecipients
    this.setTransferWithMethod()
    this.apply()
  }

  apply (): void {
    this.setManualValidatedTransfers(this.transferWithMethod)
    this.$router.push('manualApply')
  }

  setTransferWithMethod (): void {
    this.countryWithMethods = Object.keys(this.recipients)
    this.countryWithMethods.forEach(countryWithMethod => {
      if (!this.recipients[countryWithMethod].length) return
      this.transferWithMethod[countryWithMethod] = this.recipients[countryWithMethod].map((recipient: Recipient): TransferRow => {
        return {
          corps_id: recipient.corp_pid,
          full_name: `${recipient.first_name}${recipient.middle_name ?? ''} ${recipient.last_name ?? ''}`,
          amount: '0',
          base_currency: 'USD',
          invoice: { value: '', name: '' },
          comment: '',
          updatable: true
        }
      })
    })
    this.transferWithMethod = Object.assign({}, this.transferWithMethod)
  }

  goToHistoryPage () {
    this.$message({
      message: `${this.$t('notification.deposit_first_and_request')}`,
      type: 'warning'
    })
    this.$router.replace('/history')
  }

  async hasWaitRemittanceGroup (): Promise<boolean> {
    if (!MemberInfoGateway.hasAuthInfo()) {
      await this.$router.push('/signIn')
      return false
    }
    const memberInfo: MemberInfo = MemberInfoGateway.getMemberInfo()
    const params = { corp_id: memberInfo.corp_id }
    const hasWaitingRemittance = await WaitRemittanceGroup.getInstance().request(params)
    return hasWaitingRemittance
  }

  async created () {
    if (await this.hasWaitRemittanceGroup()) return this.goToHistoryPage()
    this.setManualValidatedTransfers({})
    this.setValidatedTransfers({})
  }
}
